import { SwPush, SwUpdate } from '@angular/service-worker';
import { SubscriptionServiceProxy, PushSubscriptionDto, WebPushSubscriptionOutput, WebPushNotificationParametersOutputDto } from '../service-proxies/process-proxy';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@shared/utils/local-storage.service';
import { AppConsts } from '@shared/AppConsts';
import { WebPushBrowserUIDModel } from '@shared/models/webpushbrowseruid.model';

@Injectable({
    providedIn: 'root',
})
export class PushNotificationsManager {

    constructor(
        private swPush: SwPush,
        private swUpdate: SwUpdate,
        private _subscriptionService: SubscriptionServiceProxy,
        private _localStorageService: LocalStorageService) {
    }

    public subscribeNotifications(): void {
        if (this.swUpdate.isEnabled) {
            this._subscriptionService.getNotificationsPublicKey()
                .subscribe((parameters: WebPushNotificationParametersOutputDto) => {
                    this.subscribeUserToWebPush(parameters.publicKey);
                });
        }
    }

    private subscribeUserToWebPush(publicKey: string): void {
        this.swPush.requestSubscription({ serverPublicKey: publicKey })
            .then(sub => {
                this.postSubscription(sub);
            });

    }

    private postSubscription(sub: PushSubscription) {
        let input = new PushSubscriptionDto({
            endpoint: sub.endpoint,
            expirationTime: null,
            auth: btoa(String.fromCharCode.apply(null, new Uint8Array(sub.getKey('auth')))),
            p256dh: btoa(String.fromCharCode.apply(null, new Uint8Array(sub.getKey('p256dh'))))
        });

        this._subscriptionService.subscribeToPushNotification(input)
            .subscribe((result: WebPushSubscriptionOutput) => {
                if (result.isError === false) {
                    let obj = new WebPushBrowserUIDModel();
                    obj.token = result.browserIdentifier;
                    obj.needToCheck = false;
                    this._localStorageService.setItem(AppConsts.LocalStorageBrowserWebPushKey, obj);
                }
            });
    }

    checkCurrentUserByBrowserWebSubscription() {
        this._localStorageService.getItem(AppConsts.LocalStorageBrowserWebPushKey, (err, value: WebPushBrowserUIDModel) => {
            if (value && value.token && value.needToCheck === true) {
                this._subscriptionService.checkCurrentUserByBrowserWebSubscription(value.token)
                    .subscribe(_ => {
                        this.updateLastCheckedBrowserWebPushIdTime(value);
                    });
            }
        })
    }

    disableCurrentSubscription() {
        this._localStorageService.getItem(AppConsts.LocalStorageBrowserWebPushKey, (err, value: WebPushBrowserUIDModel) => {
            if (value && value.token && value.needToCheck === true) {
                this._subscriptionService.disableCurrentUserByBrowserWebSubscription(value.token)
                    .subscribe();
            }
        })
    }

    clearBrowserUID() {
        this._localStorageService.removeItem(AppConsts.LocalStorageBrowserWebPushKey);
    }

    private updateLastCheckedBrowserWebPushIdTime(obj: WebPushBrowserUIDModel) {
        obj.needToCheck = false;
        this._localStorageService.setItem(AppConsts.LocalStorageBrowserWebPushKey, obj);
    }
}

