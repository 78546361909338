import { Component, Injector, OnInit, Input } from '@angular/core';
import { ThemesLayoutBaseComponent } from '../../themes/themes-layout-base.component';
import { PwaService } from '@shared/pwa-service';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';

@Component({
    selector: 'install-pwa',
    templateUrl: './install-pwa.component.html',
})
export class InstallPWAComponent extends ThemesLayoutBaseComponent implements OnInit {

    @Input() customStyle = 'btn btn-icon btn-dropdown btn-clean btn-lg mr-1';
    @Input() isDropup = false;

    public constructor(
        injector: Injector,
        private _pwaService: PwaService,
        _dateTimeService: DateTimeService
    ) {
        super(injector, _dateTimeService);
    }

    ngOnInit(): void {
    }

    showPromptPWA(): void {
        this._pwaService.addToHomeScreen();
    }

    showBtnToInstallNative() {
        return this._pwaService.browserPromptBtn();
    }

    showHelpIOS() {
        return this._pwaService.iOSSafariHow2();
    }

    whereIsShare() {
        return this._pwaService.whereIsShare === 'bottom' ? this.l('PWAInstructionsStep1-bottom') : this.l('PWAInstructionsStep1-top');
    }
}
