import { Injectable, Injector, NgZone } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { HubConnection } from '@microsoft/signalr';
import { AppConsts } from '../../../shared/AppConsts';

@Injectable()
export class StatusPreActivityReportSynchronizationSignalrService extends AppComponentBase {

    private eventPreActivityReportStatusChanged = 'eventPreActivityReportStatusChanged';
    private eventPreActivityReportPhotoApprovalChanged = 'eventPreActivityReportPhotoApprovalChanged';
    private eventPreActivityReportCanceled = 'eventPreActivityReportCanceled';
    private eventPreActivityReportTripDriverUpdated = 'eventPreActivityReportTripDriverUpdated';
    private eventPreActivityReportChangeToReport = 'eventPreActivityReportChangeToReport';
    private eventPreActivityReportTurnExpired = 'eventPreActivityReportTurnExpired';
    private eventPreActivityReportEdited = 'eventPreActivityReportEdited';

    constructor(
        injector: Injector,
        public _zone: NgZone,
    ) {
        super(injector);
    }

    statusHub: HubConnection;
    isStatusConnected = false; 
    configureConnection(connection): void {
    // Set the common hub
        this.statusHub = connection;

        // Reconnect loop
        let reconnectTime = 5000;
        let tries = 1;
        let maxTries = 8;
        function start() {
            return new Promise(function (resolve, reject) {
                if (tries > maxTries) {
                        reject();
                } else {
                    connection.start()
                        .then(resolve)
                        .then(() => {
                            reconnectTime = 5000;
                            tries = 1;
                        })
                        .catch(() => {
                            setTimeout(() => {
                                start().then(resolve);
                            }, reconnectTime);
                            reconnectTime *= 2;
                            tries = 1;
                        });
                }
            });
        }
        // Reconnect if hub disconnects
        connection.onclose(e => {
            this.isStatusConnected = false;
            this.notify.info(this.l('ConnectionLostTryingToReconnect'));
            if (e) {
                abp.log.debug('Status connection closed with error: ' + e);
            } else {
                abp.log.debug('Status disconnected');
            }
            start().then(() => {
                this.isStatusConnected = true;
                this.notify.info(this.l('Connected'));
                abp.event.trigger(AppConsts.AppStatusPreActivityReportReconnected);
            });
        });

        // Register to get notifications
        this.registerStatusEvents(connection);
}

    registerStatusEvents(connection): void {
        connection.on(this.eventPreActivityReportStatusChanged, message => {
            abp.event.trigger(AppConsts.AppStatusPreActivityReportStatusChanged, message);
        });

        connection.on(this.eventPreActivityReportPhotoApprovalChanged, message => {
            abp.event.trigger(AppConsts.AppStatusPreActivityReportPhotoApprovalChanged, message);
        });

        connection.on(this.eventPreActivityReportCanceled, message => {
            abp.event.trigger(AppConsts.AppStatusPreActivityReportCanceled, message);
        });
        connection.on(this.eventPreActivityReportTripDriverUpdated, message => {
            abp.event.trigger(AppConsts.AppStatusPreActivityReportTripDriverUpdated, message);
        });

        connection.on(this.eventPreActivityReportChangeToReport, message => {
            abp.event.trigger(AppConsts.AppStatusPreActivityReportChangeToReport, message);
        });

        connection.on(this.eventPreActivityReportTurnExpired, message => {
            abp.event.trigger(AppConsts.AppStatusPreActivityReportTurnExpired, message);
        });

        connection.on(this.eventPreActivityReportEdited, message => {
            abp.event.trigger(AppConsts.AppStatusPreActivityReportEdited, message);
        });
    }

    init(): void {
        if (!this.isStatusConnected) {
            abp.log.debug('init synchronization status websocket');
            this._zone.runOutsideAngular(() => {
                abp.signalr.startConnection(abp.appPath + 'signalr-smarttruck-status', connection => {
                        this.configureConnection(connection);
                }).then(() => {
                    abp.event.trigger(AppConsts.AppStatusPreActivityReportConnected);
                        this.isStatusConnected = true;
                    }, () => {
                            abp.event.trigger(AppConsts.AppStatusPreActivityReportConnectingError);
                            this.notify.error(this.l('ErrorOnConnecting'));
                    });
                });
        } else {
            abp.event.trigger(AppConsts.AppStatusPreActivityReportAlreadyConnected);
        }
    }
}
