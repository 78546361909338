<div id="kt_header_mobile" class="header-mobile  header-mobile-fixed ">
    <a routerLink="/" target="_blank">
        <img *ngIf="!appSession.tenant || !appSession.tenant.logoId" src="/assets/common/images/app-logo-on-light-sm.svg" alt="logo" height="28" />
        <img *ngIf="appSession.tenant && appSession.tenant.logoId" [src]="remoteServiceBaseUrl + '/TenantCustomization/GetTenantLogo?skin=' + currentTheme.baseSettings.menu.asideSkin + '&tenantId=' + appSession.tenant.id + '&id=' + appSession.tenant.logoId" alt="logo" height="38" />
    </a>
    <div class="d-flex align-items-center">
        <button class="btn p-0 burger-icon rounded-0 burger-icon-left" id="kt_aside_tablet_and_mobile_toggle">
            <span></span>
        </button>
        <button ktToggle [options]="userMenuToggleOptions" class="btn btn-hover-text-primary p-0 ml-3" id="kt_header_mobile_topbar_toggle">
            <span class="svg-icon svg-icon-xl">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                        <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                        <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fill-rule="nonzero"></path>
                    </g>
                </svg>
            </span>
        </button>
    </div>
</div>

<div [@routerTransition] class="d-flex flex-column flex-root">
    <div class="d-flex flex-row flex-column-fluid page">
        <div class="aside aside-left d-flex flex-column flex-row-auto" id="kt_aside">
            <div class="aside-menu-wrapper flex-column-fluid scroll" id="kt_aside_menu_wrapper">
                <side-bar-menu></side-bar-menu>
            </div>
        </div>
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
            <div id="kt_header" ktHeader #ktHeader class="header header-fixed ">
                <div class=" container  d-flex align-items-stretch justify-content-between">
                    <div class="d-none d-lg-flex align-items-center mr-3">
                        <button class="btn btn-icon aside-toggle ml-n3 mr-10" id="kt_aside_toggle">
                            <span class="svg-icon svg-icon-xxl svg-icon-dark-75">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24"></rect>
                                        <rect fill="#000000" opacity="0.3" x="4" y="5" width="16" height="2" rx="1"></rect>
                                        <rect fill="#000000" opacity="0.3" x="4" y="13" width="16" height="2" rx="1"></rect>
                                        <path d="M5,9 L13,9 C13.5522847,9 14,9.44771525 14,10 C14,10.5522847 13.5522847,11 13,11 L5,11 C4.44771525,11 4,10.5522847 4,10 C4,9.44771525 4.44771525,9 5,9 Z M5,17 L13,17 C13.5522847,17 14,17.4477153 14,18 C14,18.5522847 13.5522847,19 13,19 L5,19 C4.44771525,19 4,18.5522847 4,18 C4,17.4477153 4.44771525,17 5,17 Z" fill="#000000"></path>
                                    </g>
                                </svg>
                            </span>
                        </button>
                        <theme5-brand></theme5-brand>
                    </div>
                    <div class="topbar">
                        <div class="topbar-item subscription-warning d-print-none" *ngIf="!isMobileDevice() && subscriptionStatusBarVisible()">
                            <subscription-notification-bar></subscription-notification-bar>
                        </div>
                        <active-delegated-users-combo [customStyle]="'btn btn-sm btn-clean btn-text-dark-75 mr-1'"></active-delegated-users-combo>
                        <quick-theme-selection [customStyle]="'btn btn-icon btn-sm btn-clean btn-text-dark-75 mr-1'"></quick-theme-selection>
                        <language-switch-dropdown [customStyle]="'btn btn-icon btn-sm btn-clean btn-text-dark-75 mr-1'"></language-switch-dropdown>
                        <header-notifications [customStyle]="'btn btn-icon btn-sm btn-clean btn-text-dark-75 mr-1'"></header-notifications>
                        <user-menu [togglerCssClass]="'btn btn-sm btn-clean btn-text-dark-75 mr-1'"></user-menu>
                    </div>
                </div>
            </div>
            <div class="p-3 mobile-subscription-warning d-print-none" *ngIf="isMobileDevice() && subscriptionStatusBarVisible()">
                <subscription-notification-bar></subscription-notification-bar>
            </div>
            <div style="flex:1">
                <router-outlet></router-outlet>
            </div>
            <footer-bar></footer-bar>
        </div>
    </div>
</div>
