<div *ngIf="showBtnToInstallNative()" class="topbar-item" id="InstallPWA" (click)="showPromptPWA()" >
    <div [class]="customStyle" id="notificationIcon2">
        <i class="fa fa-download text-white"></i>
    </div>
</div>
<!-- showHelpIOS -->
<div *ngIf="showHelpIOS()" dropdown class="topbar-item" data-toggle="dropdown" aria-expanded="true" 
    [dropup]="isDropup" container="{{isDropup ? null: 'body'}}" >
    <div dropdownToggle [class]="customStyle" id="notificationIcon">
        <i class="flaticon-download text-white"></i>
    </div>
    <div *dropdownMenu
        class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-xl">
        <div perfectScrollbar>
            <div class="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                [ngStyle]="{ 'background': 'url(' + appRootUrl() + 'assets/metronic/themes/default/images/bg/bg-1.jpg)', 'background-size': 'cover'}">
                <h4 class="d-flex flex-center rounded-top">
                    <span class="text-white">{{"AddShortcutToYourHomeScreen" | localize}}</span>
                </h4>
            </div>
            <div class="mt-10">
                <div class="navi navi-hover my-4 ps" data-scroll="true">

                    <ol>
                        <li> {{ 'PWAInstructionsStep1' | localize }}
                          <img class="nudgeImage" height="22" alt="Share Button Image" src="data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIiB4bWxucz0iaHR0cDovL3d3dy5
                      3My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMC44OCAyNy4yNSI+PGRlZnM+PHN0eWxlPi
                      5jbHMtMXtmaWxsOm5vbmU7c3Ryb2tlOiMyMzFmMjA7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5TY
                      WZhcmlfU2hhcmU8L3RpdGxlPjxwb2x5bGluZSBjbGFzcz0iY2xzLTEiIHBvaW50cz0iMTMuMTMg
                      OCAyMC4zOCA4IDIwLjM4IDI2Ljc1IDAuNSAyNi43NSAwLjUgOCA3LjUgOCIvPjxsaW5lIGNsYXN
                      zPSJjbHMtMSIgeDE9IjEwLjQ0IiB5MT0iMTciIHgyPSIxMC40NCIvPjxsaW5lIGNsYXNzPSJjbH
                      MtMSIgeDE9IjEwLjQ4IiB5MT0iMC4zOCIgeDI9IjE1LjI4IiB5Mj0iNS4xOCIvPjxsaW5lIGNsY
                      XNzPSJjbHMtMSIgeDE9IjEwLjQ0IiB5MT0iMC4zOCIgeDI9IjUuNjQiIHkyPSI1LjE4Ii8+PC9z
                      dmc+"> {{whereIsShare()}}
                      
                        </li>
                        
                        <li class="mt-5">
                            {{'PWAShortCutScrollLeft' | localize }}
                          <img class="nudgeImage" height="22" alt="Add to Home Screen button Image" src="data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIiB4bWxucz0iaHR0cDovL3d3dy5
                      3My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNSAzNSI+PHRpdGxlPlNhZmFyaV9BMkhTPC
                      90aXRsZT48cmVjdCB3aWR0aD0iMzUiIGhlaWdodD0iMzUiIHJ4PSI4IiByeT0iOCIgc3R5bGU9I
                      mZpbGw6IzhmOGY4ZiIvPjxsaW5lIHgxPSIyNC43NSIgeTE9IjE3LjUiIHgyPSIxMC4yNSIgeTI9
                      IjE3LjUiIHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlOiNmZmY7c3Ryb2tlLXdpZHRoOjJweCIvPjx
                      saW5lIHgxPSIxNy41IiB5MT0iMTAuMjUiIHgyPSIxNy41IiB5Mj0iMjQuNzUiIHN0eWxlPSJmaW
                      xsOm5vbmU7c3Ryb2tlOiNmZmY7c3Ryb2tlLXdpZHRoOjJweCIvPjwvc3ZnPg==">
                        </li>
                      </ol>
                </div>                
            </div>
        </div>
    </div>
</div>