import { AppComponentBase } from '@shared/common/app-component-base';
import { Injector } from '@angular/core';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-label',
    template: `<label><ng-content></ng-content><span *ngIf="required" class="text-danger"> *</span></label>`
})
export class AppLabelComponent extends AppComponentBase {
    @Input() required: boolean;


    constructor(
        injector: Injector,
    ) {
        super(injector);
    }
}
