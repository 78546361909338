import { Component, Injector, Input, AfterViewInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PushNotificationsManager } from '@shared/managers/push-notification.manager';

@Component({
    selector: 'subscribe-notifications',
    templateUrl: './subscribe-notifications.component.html',
})
export class SubscribeNotificationsComponent extends AppComponentBase implements AfterViewInit {

    @Input() customStyle = 'btn btn-icon btn-dropdown btn-clean btn-lg mr-1';
    isDropup: boolean = false;
    step1Localize: string = '';
    
    displayHelpDropdown: boolean = false;
    nativeNotificationAPI = 'Notification';
    private deniedPermission = 'denied';
    private defaultPermission = 'default';
    private grantedPermission = 'granted';
    
    public constructor(
        private _injector: Injector,
        private _manager: PushNotificationsManager,) {
        super(_injector);
    }
    
    ngAfterViewInit() {
        this.step1Localize = this.l('EnableWebPushNotifications-Step1', '<i class="fa fa-info-circle text-dark"></i>');
        
        if (this.notificationsAPIAreEnable()) {

            if (this.areNotificationsDeniedByUser()) {
                this._manager.clearBrowserUID();
            }else if(this.areNotificationWaitingForUserAccept()){
                this._manager.subscribeNotifications();
            } else if(this.areNotificationsGrantedByUser()){
                this._manager.checkCurrentUserByBrowserWebSubscription();
            }
        }

        this.displayHelpDropdown = this.nativeNotificationAPI in window && navigator.serviceWorker !== undefined && Notification.permission === this.deniedPermission
    }
    
    notificationsAPIAreEnable() {
        return this.nativeNotificationAPI in window && navigator.serviceWorker !== undefined;
    }

    areNotificationsDeniedByUser() {
        return Notification.permission === this.deniedPermission;
    }

    areNotificationWaitingForUserAccept() {
        return Notification.permission === this.defaultPermission;
    }

    areNotificationsGrantedByUser() {
        return Notification.permission === this.grantedPermission;
    }

}