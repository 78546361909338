import { Injectable, Injector, NgZone } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { HubConnection } from '@microsoft/signalr';
import { AppConsts } from '@shared/AppConsts';

@Injectable()
export class NotificationSignalrService extends AppComponentBase {

    private notificationsReload = 'eventReloadNotifications';

    constructor(
        injector: Injector,
        public _zone: NgZone,
    ) {
        super(injector);
    }

    notificationsHub: HubConnection;
    isNotificationWSConnected = false;

    configureNotificationsConnection(connection): void {

        this.notificationsHub = connection;

        let reconnectTime = AppConsts.SignalRReconnectTime;
        let tries = 1;
        let maxTries = AppConsts.SignalRMaxTries;
        function start() {
            return new Promise(function (resolve, reject) {
                if (tries > maxTries) {
                    reject();
                } else {
                    connection.start()
                        .then(resolve)
                        .then(() => {
                            reconnectTime = AppConsts.SignalRReconnectTime;
                            tries = 1;
                        })
                        .catch(() => {
                            setTimeout(() => {
                                start().then(resolve);
                            }, reconnectTime);
                            reconnectTime *= 2;
                            tries += 1;
                        });
                }
            });
        }

        connection.onclose(e => {
            this.isNotificationWSConnected = false;
            if (e) {
                abp.log.debug('Checkpoint connection closed with error: ' + e);
            } else {
                abp.log.debug('Checkpoint disconnected');
            }

            start().then(() => {
                this.isNotificationWSConnected = true;
            });
        });

        // Register to get notifications
        this.registerNotificationEvents(connection);
    }

    init(): void {
        this.connectSignalR();
    }

    private connectSignalR() {
        if (!this.isNotificationWSConnected) {
            this._zone.runOutsideAngular(() => {
                abp.signalr.startConnection(abp.appPath + 'signalr-notifications', (connection: Function) => {
                    this.configureNotificationsConnection(connection);
                }).then(() => {
                    this.isNotificationWSConnected = true;
                }, () => {
                    setTimeout(() => {
                        this.connectSignalR();
                    }, AppConsts.SignalRReconnectTime);
                });
            });
        }
    }

    registerNotificationEvents(connection: any) {
        connection.on(this.notificationsReload, () => {
            abp.event.trigger('app.notifications.refresh');
        });
    }
}
