<div *ngIf="iconOnly" class="topbar-item">
    <a href="javascript:;" [class]="togglerCssClass" id="kt_quick_user_toggle" data-toggle="tooltip"
       data-placement="right" data-container="body" data-boundary="window" title="" data-original-title="User Profile">
        <span class="symbol symbol-30 symbol-lg-40">
            <span class="svg-icon svg-icon-xl">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                     height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                        <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                              fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                        <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                              fill="#000000" fill-rule="nonzero"></path>
                    </g>
                </svg>
            </span>
        </span>
    </a>
</div>
<div *ngIf="!iconOnly" class="topbar-item">
    <div [class]="togglerCssClass" id="kt_quick_user_toggle">
        <i *ngIf="isImpersonatedLogin" class="fa fa-reply text-danger"></i>
        <span [class]="textCssClass">
            <span *ngIf="isMultiTenancyEnabled" class="tenancy-name">{{tenancyName}}\</span>{{userName}}
        </span>
        <span [class]="symbolCssClass">
            <span [class]="symbolTextCssClass">{{usernameFirstLetter}}</span>
        </span>
    </div>
</div>


<div id="kt_quick_user" perfectScrollbar [perfectScrollOptions]="{wheelPropagation: true, suppressScrollX: true}"
     class="offcanvas offcanvas-right p-10" ktOffcanvas [options]="offcanvasOptions">
    <!--begin::Content-->
    <div class="offcanvas-content pr-5 mr-n5">
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
            <div class="symbol symbol-100 mr-5">
                <div class="symbol-label">
                    <img [src]="profilePicture" class="symbol-label" />
                </div>
            </div>
            <div class="d-flex flex-column">
                <a href="javascript:;" class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary topbar-username">
                    <span *ngIf="isMultiTenancyEnabled" class="tenancy-name">{{tenancyName}}\</span>{{userName}}
                </a>
                <div class="navi mt-2">
                    <a href="javascript:;" (click)="logout()"
                       class="btn btn-sm btn-light-primary font-weight-bolder py-2 px-5">
                        {{"Logout" | localize}}
                    </a>
                </div>
            </div>
        </div>
        <!--end::Header-->
        <!--begin::Separator-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--end::Separator-->
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
            <a href="javascript:;" *ngIf="isImpersonatedLogin" (click)="backToMyAccount()" class="navi-item"
               id="UserProfileBackToMyAccountButton">
                <div class="navi-link">
                    <div class="symbol symbol-40 bg-light mr-3">
                        <div class="symbol-label">
                            <span class="svg-icon svg-icon-primary svg-icon-2x svg-icon-danger">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                     width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <path d="M21.4451171,17.7910156 C21.4451171,16.9707031 21.6208984,13.7333984 19.0671874,11.1650391 C17.3484374,9.43652344 14.7761718,9.13671875 11.6999999,9 L11.6999999,4.69307548 C11.6999999,4.27886191 11.3642135,3.94307548 10.9499999,3.94307548 C10.7636897,3.94307548 10.584049,4.01242035 10.4460626,4.13760526 L3.30599678,10.6152626 C2.99921905,10.8935795 2.976147,11.3678924 3.2544639,11.6746702 C3.26907199,11.6907721 3.28437331,11.7062312 3.30032452,11.7210037 L10.4403903,18.333467 C10.7442966,18.6149166 11.2188212,18.596712 11.5002708,18.2928057 C11.628669,18.1541628 11.6999999,17.9721616 11.6999999,17.7831961 L11.6999999,13.5 C13.6531249,13.5537109 15.0443703,13.6779456 16.3083984,14.0800781 C18.1284272,14.6590944 19.5349747,16.3018455 20.5280411,19.0083314 L20.5280247,19.0083374 C20.6363903,19.3036749 20.9175496,19.5 21.2321404,19.5 L21.4499999,19.5 C21.4499999,19.0068359 21.4451171,18.2255859 21.4451171,17.7910156 Z"
                                              fill="#000000" fill-rule="nonzero" />
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="navi-text">
                    <div class="font-weight-bold">
                        {{"BackToMyAccount" | localize}}
                    </div>
                    <div class="text-muted">
                        {{"BackToMyAccount_Description" | localize}}
                    </div>
                </div>
            </a>
            
            <div class="navi-item py-0 linked-accounts" *ngIf="recentlyLinkedUsers && recentlyLinkedUsers.length">
                <ul id="RecentlyUsedLinkedUsers" class="navi-text linked-accounts"
                    [attr.aria-label]="l('LinkedAccounts')" role="menu">
                    <li *ngFor="let linkedUser of recentlyLinkedUsers">
                        <a href="javascript:;" class="recently-linked-user" (click)="switchToLinkedUser(linkedUser)">
                            <span class="nav-link">
                                <span></span>
                            </span>
                            <span class="nav-link-text">
                                {{getShownUserName(linkedUser)}}
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
