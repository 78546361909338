<div [@routerTransition]>
    <div class="content d-flex flex-column flex-column-fluid">
        <sub-header [title]="'Notifications' | localize">
            <div role="actions">
                <button class="btn btn-primary" (click)="getNotifications()"><i
                    class="fa fa-sync"></i>
                </button>
                <button class="btn btn-danger" (click)="deleteNotifications()"><i
                    class="la la-times"></i>
                </button>
            </div>
        </sub-header>
        <div [class]="containerClass">
            <div class="card card-custom">
                <div class="card-body">
                    <div class="row align-items-center">
                        <!--<Primeng-TurboTable-Start>-->
                        <div class="col primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
                            <p-table #dataTable (onLazyLoad)="getNotifications($event)"
                                [value]="primengTableHelper.records"
                                rows="{{primengTableHelper.defaultRecordsCountPerPage}}" [paginator]="false"
                                [lazy]="true" [responsive]="primengTableHelper.isResponsive">
                                <ng-template pTemplate="body" let-record="$implicit">
                                    <tr>
                                        <td>
                                            <tr>
                                                <td>
                                                    <a *ngIf="record.formattedNotification.url"
                                                    href="{{record.formattedNotification.url}}"
                                                    class="{{getRowClass(record)}}">{{truncateString(record.formattedNotification.text, 120)}}
                                                    </a>
                                                    <span *ngIf="!record.formattedNotification.url"
                                                        title="{{record.formattedNotification.text}}"
                                                        class="{{getRowClass(record)}}">{{truncateString(record.formattedNotification.text, 120)}}
                                                    </span>
                                                </td>
                                                <td>
                                                    <button class="btn btn-sm btn-icon btn-danger" [attr.title]="l('Delete')"
                                                        (click)="deleteNotification(record)">
                                                        <i class="fa fa-times" [attr.aria-label]="l('Delete')"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </td>
                                        <td style="text-align:center">
                                            <span title="{{record.notification.creationTime | luxonFormat: 'DDDD t'}}"
                                                class="{{getRowClass(record)}}">
                                                {{fromNow(record.notification.creationTime)}}
                                            </span>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                                {{'NoData' | localize}}
                            </div>
                            <div class="primeng-paging-container">
                                <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage" #paginator
                                    (onPageChange)="getNotifications($event)"
                                    [totalRecords]="primengTableHelper.totalRecordsCount"
                                    [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                                </p-paginator>
                                <span class="total-records-count">
                                    {{'TotalRecordsCount' | localize:primengTableHelper.totalRecordsCount}}
                                </span>
                            </div>
                        </div>
                        <!--<Primeng-TurboTable-End>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>  