import { Injectable, Injector, NgZone } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { HubConnection } from '@microsoft/signalr';
import { AppConsts } from '../../../shared/AppConsts';

@Injectable()
export class StatusHeaderSynchronizationSignalrService extends AppComponentBase {

    private eventTruckStatusChanged = 'eventTruckStatusChanged';
    private eventDriverStatusChanged = 'eventDriverStatusChanged';

    constructor(
        injector: Injector,
        public _zone: NgZone,
    ) {
        super(injector);
    }

    statusHub: HubConnection;
    isStatusConnected = false;

    configureConnection(connection): void {
    // Set the common hub
        this.statusHub = connection;

        // Reconnect loop
        let reconnectTime = 5000;
        let tries = 1;
        let maxTries = 8;
        function start() {
            return new Promise(function (resolve, reject) {
                if (tries > maxTries) {
                        reject();
                } else {
                    connection.start()
                        .then(resolve)
                        .then(() => {
                            reconnectTime = 5000;
                            tries = 1;
                        })
                        .catch(() => {
                            setTimeout(() => {
                                start().then(resolve);
                            }, reconnectTime);
                            reconnectTime *= 2;
                            tries = 1;
                        });
                }
            });
        }
        // Reconnect if hub disconnects
        connection.onclose(e => {
            this.isStatusConnected = false;
            this.notify.info(this.l('ConnectionLostTryingToReconnect'));
            if (e) {
                abp.log.debug('Status connection closed with error: ' + e);
            } else {
                abp.log.debug('Status disconnected');
            }
            start().then(() => {
                this.isStatusConnected = true;
                this.notify.info(this.l('Connected'));
                abp.event.trigger(AppConsts.AppStatusHeaderReconnected);
            });
        });

        // Register to get notifications
        this.registerStatusEvents(connection);
}

    registerStatusEvents(connection): void {
        connection.on(this.eventTruckStatusChanged, message => {
            abp.event.trigger(AppConsts.AppStatusHeaderTruckStatusChanged, message);
        });

        connection.on(this.eventDriverStatusChanged, message => {
            abp.event.trigger(AppConsts.AppStatusHeaderDriverStatusChanged, message);
        });

    }

    init(): void {
        if (!this.isStatusConnected) {
            abp.log.debug('init synchronization status websocket');
            this._zone.runOutsideAngular(() => {
                abp.signalr.startConnection(abp.appPath + 'signalr-smarttruck-status', connection => {
                        this.configureConnection(connection);
                    }).then(() => {
                        abp.event.trigger(AppConsts.AppStatusHeaderConnected);
                        this.isStatusConnected = true;
                    }, () => {
                            abp.event.trigger(AppConsts.AppStatusHeaderConnectingError);
                            this.notify.error(this.l('ErrorOnConnecting'));
                    });
                });
        } else {
            abp.event.trigger(AppConsts.AppStatusHeaderAlreadyConnected);
        }
    }
}
