import { AbpHttpInterceptor, RefreshTokenService, AbpHttpConfigurationService } from 'abp-ng2-module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import * as ProcessApiServiceProxies from './process-proxy';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { ZeroTemplateHttpConfigurationService } from './zero-template-http-configuration.service';

@NgModule({
    providers: [
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        ApiServiceProxies.DashboardCustomizationServiceProxy,
        ApiServiceProxies.UserDelegationServiceProxy,

        ApiServiceProxies.ActivityReportServiceProxy,
        ApiServiceProxies.InternalMovementActivityReportServiceProxy,
        ApiServiceProxies.PlaceServiceProxy,
        ApiServiceProxies.DiverseProductActivityReportServiceProxy,
        ApiServiceProxies.ParameterServiceProxy,
        ProcessApiServiceProxies.LocationServiceProxy,
        ProcessApiServiceProxies.StandServiceProxy,
        ProcessApiServiceProxies.DiverseProductActivityReportServiceProxy,
        ProcessApiServiceProxies.ArticleServiceProxy,
        ProcessApiServiceProxies.VehicleServiceProxy,
        ProcessApiServiceProxies.DriverServiceProxy,
        ProcessApiServiceProxies.SingleCustomDeclarationServiceProxy,
        ProcessApiServiceProxies.SmartTruckDiverseProductActivityReportServiceProxy,
        ProcessApiServiceProxies.SmartTruckWoodActivityReportServiceProxy,
        ProcessApiServiceProxies.ContractServiceProxy,
        ProcessApiServiceProxies.ActivityReportServiceProxy,
        ProcessApiServiceProxies.SmartTruckActivityReportServiceProxy,
        ProcessApiServiceProxies.ScheduledTurnServiceProxy,
        ProcessApiServiceProxies.SmartTruckInternalMovementActivityReportServiceProxy,
        ProcessApiServiceProxies.SmartTruckServiceProxy,
        ProcessApiServiceProxies.SubscriptionServiceProxy,
        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
    ],
})
export class ServiceProxyModule { }
