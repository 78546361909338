<div *ngIf="displayHelpDropdown"
    dropdown class="topbar-item" data-toggle="dropdown" aria-expanded="true" [dropup]="isDropup"
    container="{{isDropup ? null: 'body'}}">
    <div dropdownToggle [class]="customStyle">
        <i class="fa fa-info-circle text-white"></i>
    </div>
    <div *dropdownMenu
        class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-xl">
        <div perfectScrollbar>
            <div class="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                [ngStyle]="{ 'background': 'url(' + appRootUrl() + 'assets/metronic/themes/default/images/bg/bg-1.jpg)', 'background-size': 'cover'}">
                <h4 class="d-flex flex-center rounded-top">
                    <span class="text-white">{{"EnableWebPushNotificationsTitle" | localize}}</span>
                </h4>
            </div>
            <div class="mt-10">
                <div class="navi navi-hover my-4 ps" data-scroll="true">
                    <ol>
                        <li [innerHTML]="step1Localize">
                        <li class="mt-5">
                            {{'EnableWebPushNotifications-Step2' | localize }}
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</div>