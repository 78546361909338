import { Injectable } from '@angular/core';
import { SwUpdate, SwPush } from '@angular/service-worker';
import { LocalizationService, MessageService } from 'abp-ng2-module';
import { AppConsts } from './AppConsts';
import { filter } from 'rxjs'

@Injectable({
  providedIn: 'root'
})

export class PwaService {

  localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;

  promptIntercepted = false;
  isStandalone = false;
  deferredPrompt;
  userInstalled = false;
  whereIsShare = 'bottom';

  // user agent
  isChrome = false;
  isExplorer = false;
  isExplorer_11 = false;
  isFirefox = false;
  isSafari = false;
  isOpera = false;
  isEdgeDesktop = false;
  isEdgeiOS = false;
  isEdgeAndroid = false;
  userAgent = '';

  isIOS = false;
  isMobile = false;

  promptSaved = false;
  customButtonClicked = false;
  deferredPromptShown = false;
  deferredPromptRejected = false;

  constructor(
    private _messageService: MessageService,
    private _localization: LocalizationService,
    private swPush: SwPush,
    private swUpdate: SwUpdate,
  ) {
    this.reloadCache();
  }

  checkUserAgent() {
    this.userAgent = navigator.userAgent.toLowerCase();
    const uaString = this.userAgent;

    this.isChrome = /chrome/.test(uaString);
    this.isExplorer = /msie/.test(uaString);
    this.isExplorer_11 = /rv:11/.test(uaString);
    this.isFirefox = /firefox/.test(uaString);
    this.isSafari = /safari/.test(uaString);
    this.isOpera = /opr/.test(uaString);
    this.isEdgeDesktop = /edge/.test(uaString);
    this.isEdgeiOS = /edgios/.test(uaString);
    this.isEdgeAndroid = /edga/.test(uaString);

    this.isIOS = /ipad|iphone|ipod/.test(uaString);
    this.isMobile = /mobile/.test(uaString);
    if ((this.isChrome) && (this.isSafari)) {
      this.isSafari = false;
    }
    if ((this.isChrome) &&
      (this.isEdgeDesktop || this.isEdgeiOS || this.isEdgeAndroid)
    ) {
      this.isChrome = false;
    }
    if ((this.isSafari) &&
      (this.isEdgeDesktop || this.isEdgeiOS || this.isEdgeAndroid)
    ) {
      this.isSafari = false;
    }
    if ((this.isChrome) && (this.isOpera)) {
      this.isChrome = false;
    }

    if (/ipad/.test(uaString)) {
      this.whereIsShare = 'top';
    }

  }

  private checkStandalone(): boolean {
    return (window.matchMedia('(display-mode: standalone)').matches);
  }

  trackStandalone() {
    // called once from app.component
    if (this.checkStandalone()) {
      this.isStandalone = true;
    }
  }

  trackInstalled() {
    this.userInstalled = true;
  }

  addToHomeScreen() {
    this.customButtonClicked = true;

    if (!this.deferredPrompt) {
      console.log('deferredPrompt null');
      return;
    }

    this.deferredPrompt.prompt();
    this.deferredPromptShown = true;

    this.deferredPrompt.userChoice
      .then((choiceResult) => {

        if (choiceResult.outcome === 'accepted') {

        } else {
          this.deferredPromptRejected = true;
        }
      });
  }

  browserPromptBtn() {
    return this.promptIntercepted && !this.userInstalled;
  }

  iOSSafariHow2() {
    return (this.isSafari && this.isIOS && !this.isStandalone);
  }

  showHideButton_iOS() {
    return this.isIOS && !this.userInstalled;
  }

  setDeferredPrompt(e) {
    this.deferredPrompt = e;
  }

  public initPwaPrompt() {

    this.checkUserAgent();
    this.trackStandalone();
    window.addEventListener('beforeinstallprompt', (e) => {
      this.promptIntercepted = true;
      this.deferredPrompt = e;
      this.promptSaved = true;

    });

    window.addEventListener('appinstalled', (evt) => {
      this.trackInstalled();
      this.promptIntercepted = false;
    });

  }

  private reloadCache() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.pipe(
        filter(x => x.type === "VERSION_READY")
      )
      .subscribe(() => {
        let message = this._localization.localize('NewVersionAvailableAskToReloadPage', this.localizationSourceName);
        let title= this._localization.localize('AreYouSure', this.localizationSourceName);

        this._messageService.confirm(message, undefined, result => {
          if (result) {
            window.location.reload();
          }
        })
      });
    }
  }

}
