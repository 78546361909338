import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { AppConsts } from '../../shared/AppConsts';
import { SmartTruckMonitoringService } from './smart-truck-monitoring-service.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerServiceService implements ErrorHandler {

    constructor(private injector: Injector) { }

    handleError(error: Error) {
        const keyValue = AppConsts.appInsightsInstrumentationKey;
        if (keyValue) {
            this.injector.get<SmartTruckMonitoringService>(SmartTruckMonitoringService).logException(error);
        } else {
            console.error(error);
        }
    }
}
